import React from "react";
import { graphql, PageRendererProps, Link } from "gatsby";
import Img from "gatsby-image";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { FullBlogPost, PartialBlogPost } from "../types/blog";
import { ScrollProgress } from "../components/ScrollProgress/ScrollProgress";
import { Layout } from "../components/Layout/Layout";
import { Hero } from "../components/Hero/Hero";
import { SEO } from "../components/SEO/SEO";

import styles from "./Post.module.scss";

type PostPropsType = {
  data: {
    mdx: FullBlogPost;
  };
  pageContext: {
    slug: string;
    previous?: PartialBlogPost;
    next?: PartialBlogPost;
  };
} & PageRendererProps;

const PostTemplate = ({ data: { mdx }, pageContext }: PostPropsType) => {
  const { next, previous } = pageContext;
  const { frontmatter, wordCount, timeToRead } = mdx;
  const {
    title,
    description,
    date,
    dateFormatted,
    cover,
    openGraphImage,
  } = frontmatter;

  return (
    <Layout>
      <SEO
        title={`${title} | Blog`}
        description={description}
        openGraphImage={openGraphImage.childImageSharp.fixed}
      />
      <ScrollProgress />
      <article>
        <div className={styles.header}>
          <Hero heading={title} lead={description}></Hero>
        </div>
        <div className={styles.content}>
          {cover && <Img fluid={cover.childImageSharp.fluid} />}
          <div className={styles.text}>
            <dl>
              <dt>Published</dt>
              <dd>
                <time dateTime={date}>{dateFormatted}</time>
              </dd>
              <dt>Words</dt>
              <dd>{wordCount.words}</dd>
              <dt>Reading time</dt>
              <dd>{timeToRead} minutes</dd>
            </dl>

            <MDXRenderer>{mdx.body}</MDXRenderer>
          </div>
        </div>

        {next && (
          <Link to={next.fields.slug}>
            {next.frontmatter.title} {next.frontmatter.date}
          </Link>
        )}
        {previous && (
          <Link to={previous.fields.slug}>
            {previous.frontmatter.title} {previous.frontmatter.date}
          </Link>
        )}
      </article>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      ...FullBlogPost
    }
  }
`;
