import React, { useEffect, useState } from "react";

import styles from "./ScrollProgress.module.scss";

export const ScrollProgress = () => {
  const [scrollHeight, updateScrollHeight] = useState(0);

  useEffect(() => {
    const handler = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollPercent = (winScroll / height) * 100;

      updateScrollHeight(scrollPercent);
    };

    window.addEventListener("scroll", handler);

    // clean up
    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (
    <div
      className={styles.bar}
      style={{ width: `${scrollHeight}%` }}
      aria-hidden={true}
    ></div>
  );
};
